import React, { useContext, useState, useEffect } from "react";
import { AccountContext } from "../events/Account";
import Devices from "../components/Devices";
import DeviceCard from "../components/DeviceCard2";
import DeviceOcakCard from "../components/DeviceOcakCard2";
import DeviceVanaCard from "../components/DeviceVanaCard2";
import DevicePompaCard from "../components/DevicePompaCard";
import DeviceTofCard from "../components/DeviceTofCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import VanaStroke from "../assets/vanaStroke.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { AppContext } from "./Layout";

function DevicesPage() {
  const { t, i18n } = useTranslation();

  const { apiKey, user_id } = useContext(AccountContext);
  const [fields, setFields] = useState([]);
  const [devices, setDevices] = useState([]);
  const [izci, setIzci] = useState([]);
  const [ocak, setOcak] = useState([]);
  const [lonelyDevices, setLonelyDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  var allfield = [];
  var allocak = [];
  var allizci = [];
  var alldevice = [];
  var lonelyocak = [];
  var lonelyizci = [];

  const {
    userApp,
    setUserApp,
    farmsApp,
    setFarmsApp,
    userOcakApp,
    setUserOcakApp,
    userVanaApp,
    setUserVanaApp,
    farmNameApp,
    setFarmNameApp,
    userIzciApp,
    setUserIzciApp,
  } = useContext(AppContext);

  //if app context values are not empty assign them all to local states

  const [user, setUser] = useState("");
  const [farms, setFarms] = useState([]);
  const [userOcak, setUserOcak] = useState();
  const [userVana, setUserVana] = useState();
  const [userIzci, setUserIzci] = useState();
  const [farmName, setFarmName] = useState();

  //izci filtering
  const [filteredIzci, setFilteredIzci] = useState([]);
  const [filterIzciType, setFilterIzciType] = useState(""); // "noDevice", "refill", "full"
  const [searchIzciTerm, setSearchIzciTerm] = useState("");
  const clearFilters = () => {
    setFilterIzciType("");
    setSearchIzciTerm("");
  };
  useEffect(() => {

    const filterFieldsIzci = () => {
      let filtered = userIzci;

      // Cihaz durumuna göre filtreleme
      if (filterIzciType) {
        if (filterIzciType === "refill") {
          filtered = filtered.filter((field) => field.status);
        } else if (filterIzciType === "full") {
          filtered = filtered.filter((field) => field.status);
        }
      }

      // İsme göre filtreleme
      if (searchIzciTerm) {
        filtered = filtered.filter((field) =>
          field.id.toLowerCase().includes(searchIzciTerm.toLowerCase())
        );
      }

      setFilteredIzci((prev) => filtered);
    };

    filterFieldsIzci();
  }, [userIzci, filterIzciType, searchIzciTerm]);

  //irrigation filtering
  const [filteredIrri, setFilteredIrri] = useState([]);
  const [filterIrriType, setFilterIrriType] = useState(""); // "noDevice", "refill", "full"
  const [searchIrriTerm, setSearchIrriTerm] = useState("");
  const clearFiltersIrri = () => {
    setFilterIrriType("");
    setSearchIrriTerm("");
  };
  useEffect(() => {
    const filterFieldsIrri = () => {
      let filtered = userVana;

      // Cihaz durumuna göre filtreleme
      if (filterIrriType) {
        if (filterIrriType === "refill") {
          filtered = filtered.filter((field) => field.status === "on");
        } else if (filterIrriType === "full") {
          filtered = filtered.filter((field) => field.status === "off");
        }
      }

      // İsme göre filtreleme
      if (searchIrriTerm) {
        filtered = filtered.filter((field) =>
          field.id.toLowerCase().includes(searchIrriTerm.toLowerCase())
        );
      }

      setFilteredIrri((prev) => filtered);
    };

    filterFieldsIrri();
  }, [userVana, filterIrriType, searchIrriTerm]);

  // useEffect(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("apikey", apiKey);
  //   myHeaders.append("userid", user_id);

  //   var requestOptionsGET = {
  //     method: "GET",
  //     headers: myHeaders,
  //   };
  //   //userdatafetch
  //   fetch(
  //     `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
  //     requestOptionsGET
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //       alldevice = result.body.devices && result.body.devices;
  //       allfield = result.body.fields && result.body.fields;
  //       fetch(
  //         `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userOcakDevice`,
  //         requestOptionsGET
  //       )
  //         .then((response) => response.json())
  //         .then((result) => {
  //           console.log("333 User Ocak Device :", result);
  //           allocak =
  //             result.body &&
  //             result.body.filter(
  //               (field) =>
  //                 field?.user_deleted_at == undefined ||
  //                 field?.user_deleted_at == false
  //             );
  //           fetch(
  //             `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userIzciDevice`,
  //             requestOptionsGET
  //           )
  //             .then((response) => response.json())
  //             .then((result) => {
  //               console.log("333 User Izci Device :", result);
  //               allizci =
  //                 result.body &&
  //                 result.body.filter(
  //                   (field) =>
  //                     field?.user_deleted_at == undefined ||
  //                     field?.user_deleted_at == false
  //                 );
  //               allizci = allizci.sort((a, b) => a.izci_length - b.izci_length);
  //               var tempdevices;
  //               allfield.map((f, inf) => {
  //                 if (f.devices) {
  //                   f.devices.map((d, ind) => {
  //                     allocak.find((o, ino) => {
  //                       if (o.id == d) {
  //                         console.log(
  //                           "find method oca :",
  //                           o,
  //                           "index oca: ",
  //                           ino
  //                         );
  //                         o.fieldName = f.name;
  //                         allfield[inf].devices[ind] = o;
  //                       }
  //                     });
  //                     allizci.find((o, ino) => {
  //                       if (o.id == d) {
  //                         console.log(
  //                           "find method izc:",
  //                           o,
  //                           "index izc: ",
  //                           ino
  //                         );
  //                         allizci[ino].fieldName = f.name;
  //                         console.log("o.fieldName", f);
  //                         console.log("o.fieldName2", o);
  //                         console.log("o.fieldName3", allizci);
  //                         allfield[inf].devices[ind] = o;
  //                       }
  //                     });
  //                   });
  //                 }
  //               });
  //               setFields(allfield);
  //               setDevices(alldevice);
  //               setOcak(allocak);
  //               setIzci(allizci);
  //               setLoading((prev) => false);
  //             })
  //             .catch((error) => {
  //               console.log("error", error);
  //               setLoading((prev) => false);
  //             });
  //         })
  //         .catch((error) => {
  //           console.log("error", error);
  //           setLoading((prev) => false);
  //         });
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       setLoading((prev) => false);
  //     });
  //   //userocakfetch

  //   //userizcifetch
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("fetching user devices");

        const myHeaders = new Headers();
        myHeaders.append("apikey", apiKey);
        myHeaders.append("userid", user_id);

        const requestOptionsGET = {
          method: "GET",
          headers: myHeaders,
        };
        const userResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}`,
          requestOptionsGET
        );

        const userData = await userResponse.json();
        setUserApp(userData.body);
        console.log("userData", userData);
        const alldevice = userData.body.devices && userData.body.devices;
        const allfield = userData.body.fields && userData.body.fields;
        GetUserFarm(allfield);


        const userOcakDeviceResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userOcakDevice`,
          requestOptionsGET
        );
        var userOcakDeviceData = await userOcakDeviceResponse.json();
        userOcakDeviceData = userOcakDeviceData.body.filter(
          (obj) =>
            obj.user_deleted_at == undefined || obj.user_deleted_at == false
        );

        allfield?.map((f, inf) => { 
          if (f.devices) {
            f.devices.map((d, ind) => {
              userOcakDeviceData.find((o, ino) => {
                if (o.id == d) {
                  console.log(
                    "find method oca :",
                    o,
                    "index oca: ",
                    ino
                  );
                  o.fieldName = f.name;
                }
              })
            })
          }
        });
                
        setUserOcak((prev) => userOcakDeviceData);
        setUserOcakApp((prev) => userOcakDeviceData);

        var userIzciDeviceResponse = await fetch(
          `https://dhly26avwl.execute-api.eu-west-1.amazonaws.com/v1/user/${user_id}/userIzciDevice`,
          requestOptionsGET
        );
        var userIzciDeviceDataAll = await userIzciDeviceResponse.json();
        console.log("userDeviceDataAll", userIzciDeviceDataAll);
        var userIzciDeviceData = userIzciDeviceDataAll.body.filter(
          (obj) =>
            obj.type == "izci" &&
            (obj.user_deleted_at == undefined || obj.user_deleted_at == false)
        );
        console.log("userIzciDeviceData", userIzciDeviceData);
        allfield?.map((f, inf) => { 
          if (f.devices) {
            f.devices.map((d, ind) => {
              userIzciDeviceData.find((o, ino) => {
                if (o.id == d) {
                  console.log(
                    "find method oca :",
                    o,
                    "index oca: ",
                    ino
                  );
                  o.fieldName = f.name;
                }
              })
            })
          }
        });
        setUserIzci((prev) => userIzciDeviceData);
        setUserIzciApp((prev) => userIzciDeviceData);

        var userVanaDeviceData = userIzciDeviceDataAll.body.filter(
          (obj) => obj.type == "vana" &&
          (obj.user_deleted_at == undefined || obj.user_deleted_at == false)
        );
        console.log("userVanaDeviceData", userVanaDeviceData);
        allfield?.map((f, inf) => { 
          if (f.devices) {
            f.devices.map((d, ind) => {
              userVanaDeviceData.find((o, ino) => {
                if (o.id == d) {
                  console.log(
                    "find method oca :",
                    o,
                    "index oca: ",
                    ino
                  );
                  o.fieldName = f.name;
                }
              })
            })
          }
        });
        setUserVana((prev) => userVanaDeviceData);
        setUserVanaApp((prev) => userVanaDeviceData);

        setLoading((prev) => false);
      } catch (error) {
        console.log("error", error);
        setLoading((prev) => false);
        // Hata durumunda kullanıcıya bilgilendirme yapılabilir
      }
    };
    // if appcontext is empty, fetch user data
    if (!userOcakApp || !userVanaApp || !userIzciApp) {
      fetchData();
    } else {
      console.log("contexten alındı ctxdevices");

      userOcakApp && setUserOcak(userOcakApp);
      userVanaApp && setUserVana(userVanaApp);
      userIzciApp && setUserIzci(userIzciApp);
      setLoading((prev) => false);
    }
  }, [apiKey, user_id]);

  const GetUserFarm = (allfield) => {
    const Farms = {};

    // Veriyi "farmName" özelliğine göre grupla
    allfield?.forEach((item) => {
      if (item.farmName) {
        setFarmName((prev) => item.farmName);
        setFarmNameApp((prev) => item.farmName);
        if (!Farms[item.farmName]) {
          Farms[item.farmName] = [item];
        } else {
          Farms[item.farmName].push(item);
        }
      }
    });
    console.log("Farms", Farms);
    setFarms((prev) => Farms);
    setFarmsApp((prev) => Farms);
  };

  return (
    <div className="flex flex-col p-5  max-sm:pt-0  mx-auto   sm:px-6  max-sm:justify-center  lg:pl-8">
      <div
        id="myElement13"
        className="myElement13 z-[500] sticky top-0 w-screen lg:w-full lg:relative lg:bg-none px-5 -ml-5 max-sm:bg-[#f8f9fa] max-sm:pt-10 pb-4 pt-4 transition-all duration-300  ease-in-out delay-75"
      >
        <p className="text-base text-textDark font-semibold ">{t("devices")}</p>
        <div id="bigselam">
          <p className="text-textLight text-xs selam" id="selam">
            {t("monitor")}
          </p>
          {/* <div className="w-full pt-2 h-8  text-textDark text-xs font-bold flex flex-row gap-2">
              <span className="bg-white px-2 py-1 rounded-md shadow-miniMap">
                {" "}
                optimal
              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('refill')}
              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('over')}

              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('on')}

              </span>
              <span className="bg-white px-4 py-1 rounded-md shadow-miniMap">
                {" "}
                {t('off')}

              </span>
            </div> */}
        </div>
      </div>
      {loading ? (
        <div className=" w-full flex max-sm:flex-col flex-row gap-4">
          <div className="w-full ">
            <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fff">
              <Skeleton width={"60%"} height={30} />
              <Skeleton width={"100%"} height={200} />
            </SkeletonTheme>
          </div>
        </div>
      ) : userIzci?.length > 0 || userOcak?.length > 0 || userVana?.length > 0 ? (
        <div className="flex flex-row max-sm:flex-col w-full   flex-wrap max-sm:mb-40">
          <div className="flex flex-col max-sm:w-full  w-[37%] mr-6 ">
            <div className="bg-white w-[100%] max-h-[20rem] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-transparent  max-sm:px-0 max-sm:py-0 max-sm:bg-transparent px-6 py-6 rounded-xl  mb-6 max-sm:mb-0 overflow-x-clip">
              <p className="text-textDark font-semibold text-base  mb-0 max-sm:mb-0 w-full ">
               {t("devicesWeatherStation")}
              </p>
              {userOcak?.map((e) => {
                return <DeviceOcakCard data={e} key={e.id} />;
              })}
              {!userOcak && <div>no device</div>}
            </div>
            <div className="bg-white w-[100%] min-h-[12rem]  px-6 py-6 rounded-xl max-sm:px-0 max-sm:py-0  max-sm:bg-transparent max-sm:mb-12">
              <p className="text-textDark font-semibold text-base mb-1">
                {t("irrigation")}
              </p>
              <div className="w-full h-8  mt-0 mb-4 flex flex-row gap-2 ml-0 max-sm:ml-0 whitespace-nowrap">
                <div
                  className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                    filterIrriType === "refill"
                      ? "bg-blue-500 text-white shadow-none"
                      : "bg-white text-textDark"
                  }`}
                  onClick={() =>
                    filterIrriType === "refill"
                      ? clearFiltersIrri()
                      : setFilterIrriType("refill")
                  }
                >
                  {t("on")}
                </div>
                <div
                  className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                    filterIrriType === "full"
                      ? "bg-blue-500 text-white shadow-none"
                      : "bg-white text-textDark"
                  }`}
                  onClick={() =>
                    filterIrriType === "full"
                      ? clearFiltersIrri()
                      : setFilterIrriType("full")
                  }
                >
                  {t("off")}
                </div>
                <div className="flex flex-row bg-white w-fit  h-full text-xs px-2 text-textDark font-semibold  shadow-miniMap rounded-lg justify-center items-center">
                  <p className="text-sm mr-1">
                    <ion-icon name="search-outline"></ion-icon>
                  </p>
                  <input
                    className="w-20 h-full focus:outline-none outline-none focus:w-60 transition-all"
                    value={searchIrriTerm}
                    onChange={(e) => setSearchIrriTerm(e.target.value)}
                  />
                </div>
                {filterIrriType && (
                  <div
                    className="bg-white h-full text-lg px-2 text-textDark font-semibold  shadow-miniMap rounded-lg cursor-pointer flex justify-center items-center"
                    onClick={clearFiltersIrri}
                  >
                    <ion-icon name="close-outline"></ion-icon>
                  </div>
                )}
              </div>
              <div className="  flex flex-row  flex-wrap gap-6 max-sm:gap-4 max-sm:justify-between  w-full">
                {filteredIrri?.map((e) => {
                  return (
                    e.type == "tof" && (
                      <DeviceTofCard data={e} key={Math.random()} />
                    )
                  );
                })}
                {userVana?.map((e) => {
                  return (
                    <div
                      className={`${
                        filteredIrri?.some((item) => item.id === e.id)
                          ? ""
                          : "hidden"} max-w-[12.5rem] w-[calc(50%-1rem)] max-sm:w-[calc(50%-0.5rem)] `
                      }
                    >
                      <DeviceVanaCard key={e.id} data={e} />
                    </div>
                  );
                })}
                {/* {filteredIrri?.map((e) => {
                  console.log("vana e", e);
                  return (
                    e.type == "vana" && <DeviceVanaCard key={e.id} data={e} />
                  );
                })} */}

                {filteredIrri?.map((e) => {
                  return (
                    e.type == "pompa" && (
                      <DevicePompaCard data={e} key={Math.random()} />
                    )
                  );
                })}
              </div>
              {userVana ? (
                ""
              ) : (
                <div className="w-full flex flex-col   z-0 rounded-t-md items-center justify-center ">
                  <img src={VanaStroke} className="w-20  mb-5" />
                  <p className="font-semibold text-base  text-textDark">
                    {t("devicesNoDevice")}
                  </p>
                  <p className="text-xs  w-3/4 text-center text-textLight">
                    {t("devicesInstallDevice")}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="bg-white w-[calc(63%-1.5rem)] min-h-[40rem]  max-sm:w-full max-sm:px-0 max-sm:py-0  max-sm:bg-transparent px-0 py-6 rounded-xl ">
            <p className="text-textDark font-semibold text-base  mb-1 w-full pl-6 max-sm:pl-0">
              {t("sensors")}
            </p>
            <div className="w-full h-8  mt-0 mb-4 flex flex-row gap-2 ml-6 max-sm:ml-0 whitespace-nowrap">
              <div
                className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                  filterIzciType === "refill"
                    ? "bg-blue-500 text-white shadow-none"
                    : "bg-white text-textDark"
                }`}
                onClick={() =>
                  filterIzciType === "refill"
                    ? clearFilters()
                    : setFilterIzciType("refill")
                }
              >
                {t("refill")}
              </div>
              <div
                className={`h-full text-xs px-2 font-semibold items-center content-center shadow-miniMap rounded-lg cursor-pointer ${
                  filterIzciType === "full"
                    ? "bg-blue-500 text-white shadow-none"
                    : "bg-white text-textDark"
                }`}
                onClick={() =>
                  filterIzciType === "full"
                    ? clearFilters()
                    : setFilterIzciType("full")
                }
              >
                {t("over")}
              </div>
              <div className="flex flex-row bg-white w-fit h-full text-xs px-2 text-textDark font-semibold  shadow-miniMap rounded-lg justify-center items-center">
                <p className="text-sm mr-1">
                  <ion-icon name="search-outline"></ion-icon>
                </p>
                <input
                  className="w-20 h-full focus:outline-none outline-none focus:w-60 transition-all"
                  value={searchIzciTerm}
                  onChange={(e) => setSearchIzciTerm(e.target.value)}
                />
              </div>
              {filterIzciType && (
                <div
                  className="bg-white h-full text-lg px-2 text-textDark font-semibold  shadow-miniMap rounded-lg cursor-pointer flex justify-center items-center"
                  onClick={clearFilters}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </div>
              )}
            </div>
            <div className="flex flex-row flex-wrap gap-7 justify-start items-start content-start pl-4">
              {/* {filteredIzci?.map((e) => {
                return (
                  e.type == "izci" && (
                    <div className="max-sm:w-full w-[22rem] ">
                      <DeviceCard data={e} key={Math.random()} />
                    </div>
                  )
                );
              })} */}
              {userIzci?.map((e) => {
                return (
                  <div
                    className={`${
                      filteredIzci?.some((item) => item.id === e.id)
                        ? ""
                        : "hidden"
                    } max-sm:w-full w-[22rem] `}
                  >
                    <DeviceCard data={e} key={e.id} />
                  </div>
                );
              })}
            </div>

            {userIzci ? (
              ""
            ) : (
              <div className="w-full flex flex-col   z-0 rounded-t-md items-center justify-center ">
                <img src={VanaStroke} className=" w-20  mb-5" />
                <p className="text-lg font-semibold text-textDark">
                  {t("devicesNoDevice")}
                </p>
                <p className="text-sm font-medium w-3/4 text-center text-textLight">
                  {t("devicesInstallDevice")}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full p-6 flex flex-col border-2  border-dashed  z-0 rounded-t-md items-center justify-center ">
          <img src={VanaStroke} className="w-20  mb-5" />
          <p className="text-base font-semibold text-textDark">
            {t("devicesNoDevice")}
          </p>
          <p className="text-xs font-normal w-3/4 text-center text-textLight">
            {t("devicesInstallDevice")}
          </p>
          <Link
            to="/setupDevice"
            className="text-xs font-semibold px-10 py-3 bg-blue-500 text-white mt-2 rounded-md"
          >
            {t("navSetupDevice")}
          </Link>
        </div>
      )}
    </div>
  );
}

export default DevicesPage;
