import React, { useEffect, useState, useContext } from "react";
import ReactEcharts from "echarts-for-react";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import izciFull from "../assets/izcinew.png";
import izci90 from "../assets/izcinew.png";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../events/Account";
import Trendline from "../assets/trendline.png";
import * as echarts from "echarts";
import { AppContext } from "../pages/Layout";

function DeviceCard({ data, selectedDevice }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lastData, setLastData] = useState();
  const [tsdata, settsdata] = useState();
  const [full, setfull] = useState(false);
  const [more, setMore] = useState(false);
  const [loading, setLoading] = useState(true);

  const [connection, setConnection] = useState(3);
  const [newdata, setnewdata] = useState(false);
  const { apiKey, user_id } = useContext(AccountContext);
  const { userIzciApp, setUserIzciApp } = useContext(AppContext);
  const [tempcurrent, setTempCurrent] = useState([
    lastData?.izci_at
      ? {
          at: Number(lastData.izci_at).toFixed(2),
        }
      : null,
    lastData?.izci_sf_05
      ? {
          mois:
            Number(lastData.izci_sf_05) < 400000000 ||
            Number(lastData.izci_sf_05) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_05)) / 1000000).toFixed(
                  2
                ),
          temp: Number(lastData.izci_st_05).toFixed(0),
          cm: "05",
        }
      : null,
    lastData?.izci_sf_15
      ? {
          mois:
            Number(lastData.izci_sf_15) < 400000000 ||
            Number(lastData.izci_sf_15) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_15)) / 1000000).toFixed(
                  2
                ),
          temp: Number(lastData.izci_st_15).toFixed(0),
          cm: "15",
        }
      : null,
    lastData?.izci_sf_25
      ? {
          mois:
            Number(lastData.izci_sf_25) < 400000000 ||
            Number(lastData.izci_sf_25) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_25)) / 1000000).toFixed(
                  2
                ),
          temp: Number(lastData.izci_st_25).toFixed(0),
          cm: "25",
        }
      : null,
    lastData?.izci_sf_35
      ? {
          mois:
            Number(lastData.izci_sf_35) < 400000000 ||
            Number(lastData.izci_sf_35) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_35)) / 1000000).toFixed(
                  2
                ),
          temp: Number(lastData.izci_st_35).toFixed(0),
          cm: "35",
        }
      : null,
    lastData?.izci_sf_45
      ? {
          mois:
            Number(lastData.izci_sf_45) < 400000000 ||
            Number(lastData.izci_sf_45) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_45)) / 1000000).toFixed(
                  2
                ),
          temp: Number(lastData.izci_st_45).toFixed(0),
          cm: "45",
        }
      : null,
    lastData?.izci_sf_55
      ? {
          mois:
            Number(lastData.izci_sf_55) < 400000000 ||
            Number(lastData.izci_sf_55) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_55)) / 1000000).toFixed(
                  2
                ),
          temp: lastData?.izci_st_55 && Number(lastData.izci_st_55).toFixed(0),
          cm: "55",
        }
      : null,
    lastData?.izci_sf_65
      ? {
          mois:
            Number(lastData.izci_sf_65) < 400000000 ||
            Number(lastData.izci_sf_65) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_65)) / 1000000).toFixed(
                  2
                ),
          temp: lastData?.izci_st_65 && Number(lastData.izci_st_65).toFixed(0),
          cm: "65",
        }
      : null,
    lastData?.izci_sf_75
      ? {
          mois:
            Number(lastData.izci_sf_75) < 400000000 ||
            Number(lastData.izci_sf_75) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_75)) / 1000000).toFixed(
                  2
                ),
          temp: lastData?.izci_st_75 && Number(lastData.izci_st_75).toFixed(0),
          cm: "75",
        }
      : null,
    lastData?.izci_sf_85
      ? {
          mois:
            Number(lastData.izci_sf_85) < 400000000 ||
            Number(lastData.izci_sf_85) > 500000000
              ? 12
              : ((500000000 - Number(lastData.izci_sf_85)) / 1000000).toFixed(
                  2
                ),
          temp: lastData?.izci_st_85 && Number(lastData.izci_st_85).toFixed(0),
          cm: "85",
        }
      : null,
  ]);

  useEffect(() => {
    setTempCurrent([
      lastData?.izci_at
        ? {
            at: Number(lastData.izci_at).toFixed(2),
          }
        : null,
      lastData?.izci_sf_05 != undefined
        ? {
            mois:
              // Number(lastData.izci_sf_05) < 400000000 ||
              // Number(lastData.izci_sf_05) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_05)) / 1000000).toFixed(
              //       2
              //     ),
                FreqToMois(Number(lastData.izci_sf_05) , "izci_sf_05"),
            temp: Number(lastData.izci_st_05).toFixed(0),
            cm: "05",
          }
        : null,
      lastData?.izci_sf_15 != undefined
        ? {
            mois:
              // Number(lastData.izci_sf_15) < 400000000 ||
              // Number(lastData.izci_sf_15) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_15)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_15) , "izci_sf_15"),
            temp: Number(lastData.izci_st_15).toFixed(0),
            cm: "15",
          }
        : null,
      lastData?.izci_sf_25 != undefined
        ? {
            mois:
              // Number(lastData.izci_sf_25) < 400000000 ||
              // Number(lastData.izci_sf_25) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_25)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_25) , "izci_sf_25"),
            temp: Number(lastData.izci_st_25).toFixed(0),
            cm: "25",
          }
        : null,
      lastData?.izci_sf_35 != undefined && data.izci_length > 30
        ? {
            mois:
              // Number(lastData.izci_sf_35) < 400000000 ||
              // Number(lastData.izci_sf_35) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_35)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_35) , "izci_sf_35"),
            temp: Number(lastData.izci_st_35).toFixed(0),
            cm: "35",
          }
        : null,
      lastData?.izci_sf_45 != undefined && data.izci_length > 30
        ? {
            // mois:
            //   Number(lastData.izci_sf_45) < 400000000 ||
            //   Number(lastData.izci_sf_45) > 500000000
            //     ? 12
            //     : ((500000000 - Number(lastData.izci_sf_45)) / 1000000).toFixed(
            //         2
            //       ),
            mois: FreqToMois(Number(lastData.izci_sf_45) , "izci_sf_45"),
            temp: Number(lastData.izci_st_45).toFixed(0),
            cm: "45",
          }
        : null,
      lastData?.izci_sf_55 != undefined && data.izci_length > 30
        ? {
            mois:
              // Number(lastData.izci_sf_55) < 400000000 ||
              // Number(lastData.izci_sf_55) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_55)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_55) , "izci_sf_55"),
            temp:
              lastData?.izci_st_55 && Number(lastData.izci_st_55).toFixed(0),
            cm: "55",
          }
        : null,
      lastData?.izci_sf_65 != undefined && data.izci_length > 60
        ? {
            mois:
              // Number(lastData.izci_sf_65) < 400000000 ||
              // Number(lastData.izci_sf_65) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_65)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_65) , "izci_sf_65"),
            temp:
              lastData?.izci_st_65 && Number(lastData.izci_st_65).toFixed(0),
            cm: "65",
          }
        : null,
      lastData?.izci_sf_75 != undefined && data.izci_length > 60
        ? {
            mois:
              // Number(lastData.izci_sf_75) < 400000000 ||
              // Number(lastData.izci_sf_75) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_75)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_75) , "izci_sf_75"),
            temp:
              lastData?.izci_st_75 && Number(lastData.izci_st_75).toFixed(0),
            cm: "75",
          }
        : null,
      lastData?.izci_sf_85 != undefined && data.izci_length > 60
        ? {
            mois:
              // Number(lastData.izci_sf_85) < 400000000 ||
              // Number(lastData.izci_sf_85) > 500000000
              //   ? 12
              //   : ((500000000 - Number(lastData.izci_sf_85)) / 1000000).toFixed(
              //       2
              //     ),
              FreqToMois(Number(lastData.izci_sf_85) , "izci_sf_85"),
            temp:
              lastData?.izci_st_85 && Number(lastData.izci_st_85).toFixed(0),
            cm: "85",
          }
        : null,
    ]);
  }, [lastData]);
  useEffect(() => {
    const fetchData2 = () => {
      const fetch1 = () => {
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=7d&izcilength=${data.izci_length}&created_at=${data.created_at}`
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.body) {
              var obj1 = [];
              result.body.map((e) => {
                obj1.push(JSON.parse(e));
              });
              settsdata((prev) => obj1);
              //find device from userVanaApp array and add tsdata with 7d key if userVanaApp array includes device
              if (userIzciApp && userIzciApp.length > 0) {
                var index = userIzciApp.findIndex((x) => x.id === data.id);
                console.log("index", index);
                var temp = userIzciApp;
                if (index !== -1) {
                  temp[index].tsdata7d = obj1;
                  setUserIzciApp((prev) => temp);
                }
                console.log("userIzciApp", userIzciApp);
              }
            }
          })
          .catch((error) => {
            console.log("izci error", error);
          });
      };

      const fetch2 = () => {
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=last&izcilength=${data.izci_length}&created_at=${data.created_at}`
        )
          .then((response) => response.json())
          .then((result) => {
            var obj2 = result.body.map((e) => JSON.parse(e));
            console.log("izci lastdata:", obj2);
            obj2 = obj2?.[0];
            setLastData((prev) => obj2);
          })
          .catch((error) => {
            console.log("izci error", error);
            setLoading((prev) => false);
          });
      };

      //if userIzciApp defined and found device in userIzciApp array and have tsdata7d, set tsdata with tsdata7d and dont call fetch1
      var index = userIzciApp
        ? userIzciApp.findIndex((x) => x.id === data.id)
        : -1;
      console.log("index", index);
      console.log("userIzciApp", userIzciApp);
      fetch2();

      if (index !== -1 && userIzciApp && userIzciApp[index].tsdata7d) {
        console.log("izci tsdata7d contextten alındı ctxizci");
        settsdata(userIzciApp[index].tsdata7d);
      } else {
        console.log("izci tsdatası fetch ile alındı ctxizci");

        fetch1();
      }
    };

    // fetchData fonksiyonunu çağır
    if (connection == 3) {
      fetchData2();
      setLoading((prev) => false);
    }

    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(
    //       `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=7d&izcilength=${data.izci_length}&created_at=${data.created_at}`,
    //       {
    //         method: "GET",
    //       }
    //       );
    //     const result = await response.json();
    //     // İkinci fetch işleminin sonucunu işle
    //     const resultfilter = JSON.parse(result.body?.[0]);

    //     setLastData((prev) => resultfilter);
    //     setLoading(false);

    //     if (result.body) {
    //       var tempalldata = [];
    //       result.body.map((e) => {
    //         tempalldata.push(JSON.parse(e));
    //       });
    //       settsdata((prev) => tempalldata);
    //     }
    //   } catch (error) {
    //     console.log("izci error", error);
    //     try {
    //       const responselast = await fetch(
    //         `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=last&izcilength=${data.izci_length}&created_at=${data.created_at}`,
    //         {
    //           method: "GET",
    //         }
    //       );
    //       const resultlast = await responselast.json();
    //       // İkinci fetch işleminin sonucunu işle
    //       const resultfilterlast = JSON.parse(resultlast.body?.[0]);

    //       setLastData((prev) => resultfilterlast);
    //       setLoading(false);
    //     } catch (error) {
    //       setLoading(false);
    //     }
    //   }
    // };

    // // fetchData fonksiyonunu çağır
    // if (connection == 3) {
    //   fetchData();
    // }
  }, [user_id]);
  useEffect(() => {
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(
      `wss://zh906rwmy8.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${data.id}]`
    );

    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener("open", (event) => {
      console.log("izci WebSocket bağlantısı başarıyla kuruldu.", event);
    });
    const pongInterval = setInterval(() => {
      socket.send("ping");
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener("message", (event) => {
      setnewdata((prev) => true);
      console.log("izci WebSocketten gelen mesaj:", event);
      var dat = JSON.parse(event.data);

      var parseddata = { ...dat.data, ...dat.data.izci_packet };
      console.log("izci parseddata", parseddata);

      setLastData((prev) => parseddata);
    });

    // Bağlantı kapandığında
    socket.addEventListener("close", (event) => {
      console.log("izci WebSocket bağlantısı kapatıldı.", event);
      clearInterval(pongInterval);

      setTimeout(() => {
        setConnection((prev) => !prev);
      }, 5000);
    });

    // Hata oluştuğunda
    socket.addEventListener("error", (event) => {
      console.error("izci WebSocket hatası:", event);
    });
    console.log("izci websocet kapalı triggered", socket.readyState);
  }, [connection, loading]);

  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;

    tsdata?.map((data, index) => {
      if (attrname.includes("izci_sf")) {
        var calc;
        // if (
        //   Number(data[attrname]) < 400000000 ||
        //   Number(data[attrname]) > 500000000
        // ) {
        //   return;
        // } else {
        //   calc = (500000000 - data[attrname]) / 1000000;
        // }
        calc = FreqToMois(Number(data[attrname]), attrname);
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(calc).toFixed(2)]);

        lasttime = Date.parse(data.time);
      } else if (index < 24 * 4) {
        var mdate = new Date(data.time);
        mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
        value.push([mdate, Number(data[attrname]).toFixed(2)]);

        lasttime = Date.parse(data.time);
      }
    });

    return value;
  }

  const DrawStackedTS = (cm) => {
    var graphData1 = yaz(`izci_sf_${cm}`);
    return StackedLine([graphData1], [`izci_sf_${cm}`]);
  };
  const DrawStackedAT = () => {
    var graphData1 = yaz(`izci_at`);
    return StackedLine([graphData1], [`izci_sf_at}`]);
  };
  function formatDate(date) {
    var mdate = new Date(date);

    mdate.setMinutes(mdate.getMinutes());
    // return `       ${mdate.getHours()}:${
    //   mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    // }
    //  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    // `;
    return `${timeDifference(mdate)}

    `;
  }
  function timeDifference(previous) {
    var current = new Date().getTime();
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ` ${t("minutes")} ${t("ago")}`;
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ` ${t("hours")} ${t("ago")}`;
    } else if (elapsed < msPerMonth) {
      return " " + Math.round(elapsed / msPerDay) + ` ${t("days")} ${t("ago")}`;
    } else if (elapsed < msPerYear) {
      return (
        " " + Math.round(elapsed / msPerMonth) + ` ${t("months")} ${t("ago")}`
      );
    } else {
      return (
        " " + Math.round(elapsed / msPerYear) + ` ${t("years")} ${t("ago")}`
      );
    }
  }

  const navigateFunction = (id) => {
    var pathname = window.location.pathname;
    var sonIndis = pathname.lastIndexOf("/"); // Son "/" işaretinin indisini buluyoruz
    var yeniDizge = pathname.substring(sonIndis + 1); // Substring metodu ile istenen kısmı alıyoruz
    if (yeniDizge !== id) {
      navigate(`${id}`, {
        state: { ...data, tsdata },
      });
    }
  };
  const location = useLocation();
  var path = location.pathname;

  const Calculate1DayDifference = (cm) => {
    if (lastData && tsdata?.[0]) {
      console.log("lastData calcdif", lastData);
      console.log("tsdata calcdif", tsdata);

      var calc =
        (FreqToMois(lastData?.[`izci_sf_${cm}`]) -
          FreqToMois(tsdata?.[tsdata.length - 23]?.[`izci_sf_${cm}`])) /
        FreqToMois(tsdata?.[tsdata.length - 23]?.[`izci_sf_${cm}`]);
      if (Number.isNaN(calc)) {
        return 0;
      } else {
        return (calc * 100).toFixed(0);
      }
    } else {
      return 0;
    }
  };
  const FreqToTrend = (freq) => {
    if (Number(freq) < 400000000 || Number(freq) > 500000000) {
      return;
    } else {
      var calc = (500000000 - freq) / 1000000;
      return calc;
    }
  };

  const FreqToMois = (freq, attrname) => {
    let newfreq;

    if (data?.havaBos && data?.suBos) {
      const havaBosValue = data.havaBos[attrname];
      const suBosValue = data.suBos[attrname];

      if (havaBosValue !== undefined && suBosValue !== undefined) {
        const range = havaBosValue - suBosValue;
        const percentage = ((havaBosValue - freq) / range) * 100;

        if (percentage < 0) {
          newfreq = 0;
        } else if (percentage > 100) {
          newfreq = 100;
        } else {
          newfreq = percentage.toFixed(2);
        }
      }
    } else {

        const range = 464710000 - 420710000;
        const percentage = ((464710000 - freq) / range) * 100;

        if (percentage < 0) {
          newfreq = 0;
        } else if (percentage > 100) {
          newfreq = 100;
        } else {
          newfreq = percentage.toFixed(2);
        }
      
    }

    // 0.000334913*POW(K2,3) - 0.0324136*POW(K2,2) + 0.888875 * K2 -1.29464
    let calc =
    0.000334913 * Math.pow(newfreq, 3) -
    0.0324136 * Math.pow(newfreq, 2) +
    0.888875 * newfreq - 
     1.29464;

    if (calc < 0) {
      calc = 0;
    } else if (calc > 100) {
      calc = 100;
    }

    return calc;
  };
  return (
    <div className="hover:cursor-pointer devicecardcontainer min-w-[20rem] w-full max-w-[24rem]   max-sm:p-0  max-sm:w-full mb-0">
      {loading ? (
        <div className=" w-full h-32 flex max-sm:flex-col flex-row gap-4">
          <div className="w-full h-32 ">
            <SkeletonTheme baseColor="#ededed" highlightColor="#f8f8f8">
              <Skeleton width={"100%"} height={"100%"} />
            </SkeletonTheme>
          </div>
        </div>
      ) : (
        <div
          className={`${
            path.includes(data.id.slice(-4)) ||
            path.length < 3 ||
            path.includes("/devices")
              ? "border-b"
              : "border-b"
          } relative border-slate-300 border-b `}
        >
          <div
            onClick={() => setMore((prev) => !prev)}
            className={`${
              more ? "-bottom-4" : "-bottom-4"
            } absolute   z-50 bg-white opac px-8 rounded-lg border -translate-x-1/2 left-1/2  py-2 text-xs text-textDark`}
          >
            {more ? "less" : "more"}
          </div>
          <div
            className={`relative  max-sm:bg-white/0 max-sm:border-none bg- transition-all duration-300 overflow-hidden rounded-2xl p-5  w-full ${
              more ? "max-h-[25rem]" : "max-h-32"
            }`}
            onClick={() => {
              navigateFunction(`${data.id.slice(-4)}`, {
                state: { ...data, tsdata },
              });
            }}
          >
            <div
              className={`${
                more ? "hidden" : ""
              } absolute  z-10  w-full h-10 left-0 bottom-0 `}
            ></div>

            <div className="absolute right-6  leftside flex flex-row items-start  top-0">
              <div
                className={`${
                  data.id.slice(-4) == "FEAF" || data.id.slice(-4) == "00FC"
                    ? "bg-[#F201AF]"
                    : data.id.slice(-4) == "0322"
                    ? "bg-[#FFA800]"
                    : ""
                }   h-28 mb-8 w-14  rounded-bl-[1.25rem] text-white font-bold `}
              >
                <div className="flex flex-col items-center justify-center pt-12">
                  <p className="text-sm">
                    {data.id.slice(-4) == "0322"
                      ? t("overshort")
                      : data.id.slice(-4) == "FEAF" ||
                        data.id.slice(-4) == "00FC"
                      ? t("refillshort")
                      : ""}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full  flex flex-col">
              <div className="flex flex-row self-end justify-self-end  w-11/12 pl-4">
                <div className="flex flex-col    min-w-[7rem]">
                  {selectedDevice ? (
                    ""
                  ) : (
                    <p className="text-base font-semibold text-textDark">
                      {data.id.slice(-4)}
                    </p>
                  )}
                  {console.log("data card2", data)}
                  <p
                    className={`${
                      lastData?.status == "on" ? "text-white " : "text-blue-500"
                    }   text-xs font- `}
                  >
                    <p className="text-xs font-semibold  whitespace-nowrap">
                      {data?.fieldName}
                    </p>
                  </p>
                  <p className=" flex flex-col text-xs text-textLight">
                    {t("fieldsLastMeasurement")}
                    <p className="">
                      {lastData?.received_date &&
                        formatDate(Number(lastData?.received_date))}
                    </p>
                  </p>
                </div>
                <div className="flex w-full flex-row justify-between pt-6">
                  <div className="flex flex-col  items-center">
                    <p className="text-xs whitespace-nowrap text-textLight leading-none">
                      {" "}
                      {t("ambienttemp")}
                    </p>
                    <p className="text-base text-textDark font-semibold leading-none">
                      {lastData?.izci_at != undefined ? lastData.izci_at : ""} °
                    </p>
                    <div className="w-20 h-6">{DrawStackedAT()}</div>
                    {/* <img className="h-6 w-20" src={Trendline} /> */}
                  </div>
                  <div className="flex flex-col  items-center ml-2">
                    <p className="text-xs whitespace-nowrap text-textLight leading-none">
                      Battery
                    </p>
                    <p className="font-semibold text-textDark leading-none whitespace-nowrap flex flex-row justify-center items-center">
                      {lastData?.izci_battery_voltage != undefined &&
                        lastData?.izci_battery_voltage}{" "}
                      <span className="text-xs pl-1">V</span>
                    </p>
                    {/* <p className="font-semibold text-sm text-blue-500">
                          {t("active")}
                        </p> */}
                  </div>
                </div>
              </div>
              <div className=" flex flex-row flex-wrap pl-8  ">
                <div className="w-full  h-full">
                  <div className="flex flex-row w-full  ">
                    <span
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(
                          "https://maps.google.com?q=" +
                            data?.coords?.lat +
                            "," +
                            data?.coords?.lon
                        );
                      }}
                      className="absolute right-0 top-0 text-lg   rounded-md  text-textDark   w-9 h-9 text-center "
                    >
                      <p className="pt-2 ">
                        <ion-icon name="map-outline"></ion-icon>
                      </p>
                    </span>
                  </div>
                  <div className="cubukcontainer flex flex-row justify-start overflow-clip ">
                    <div
                      className={`${
                        data?.izci_length == "60"
                          ? "h-[18.5rem] "
                          : "h-[9.5rem] "
                      }  w-[4.3rem]   absolute top-0 left-0`}
                    >
                      {data?.izci_length == 90 ? (
                        <img
                          src={izci90}
                          className="w-full  relati px-2 pt-3"
                        />
                      ) : (
                        <img src={izci90} className="w-full  px-2 pt-3 " />
                      )}
                    </div>
                    <div
                      className={`${
                        more ? "opacity-100" : "opacity-0"
                      } pt-2 w-full  `}
                    >
                      <div className=" w-full">
                        <div className="flex flex-row  justify-between text-textDark text-xs pb-2">
                          <p className="invisible w-8">""</p>
                          <p className="">Temp °</p>
                          <p className="">Mois %</p>
                          <p className="w-20  text-center">7 day mois</p>
                          <p className="">1 day %</p>
                        </div>
                        {tempcurrent.map((e) => {
                          return (
                            e !== null &&
                            e.cm && (
                              <div
                                key={Math.random()}
                                className="flex flex-row justify-between text-xs h-7  text-center"
                              >
                                <p className="text-textLight  w-5">
                                  {e.cm ? `${Number(e.cm)+ Number(data.margin??0) }cm` : ""}
                                </p>
                                <p className="font-semibold text-textDark  w-5">
                                  {e.temp}
                                </p>
                                <p className="font-semibold text-textDark w-5">
                                  {(e.mois / 1).toFixed(0)}
                                </p>
                                <div className="w-20 h-6 ">
                                  {DrawStackedTS(e.cm)}{" "}
                                </div>
                                {/* <img className="h-6 w-20" src={Trendline} /> */}
                                <p className="font-semibold min-w-[2rem]  text-textDark flex flex-row justify-center items-center text-center">
                                  {Calculate1DayDifference(e.cm)}
                                  <div
                                    className={`${
                                      Calculate1DayDifference(e.cm) >= 0
                                        ? "text-blue-500 "
                                        : "rotate-180 "
                                    }`}
                                  >
                                    <ion-icon name="arrow-up-outline"></ion-icon>
                                  </div>
                                </p>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className=" w-full h-1/6   ">
                  <p className="relative z-20 text-lg font-bold -mb-10  pl-3 ">
                    Soil Moisture{" "}
                  </p>
                  <div className="z-10  ">
                    {DrawStackedTS(tsdata.ts, "SM05")}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeviceCard;

function StackedLine(rawdatas, names) {
  let option = {
    // title: {
    //   show: false,
    //   text: ' asd',
    //   display: 'none',
    // },

    tooltip: {
      show: false,
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      show: false,
      type: "plain",
      top: "0%",
      padding: [
        0, // up
        0, // right
        0, // down
        45, // left
      ],
      icon: "roundRect",
    },
    toolbox: {
      show: false,
      feature: {
        dataView: { show: false, readOnly: false },
        saveAsImage: {},
      },
      top: "12%",
      z: 100000,
    },
    grid: [
      {
        left: "0%",
        right: "0%",
        bottom: "0%",
        top: "3%",
        height: "97%",
        containLabel: false,
      },
    ],
    axisLine: {
      show: false,
    },
    xAxis: {
      show: false,
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      splitLine: { show: false },
      splitArea: {
        show: false,
      },
      axisLine: { show: false },
      show: false,
      type: "value",
      max: function (value) {
        return (value.max + 0.1).toFixed(1);
      },
      min: function (value) {
        return (value.min - 0).toFixed(0);
      },
      axisTick: {
        inside: false,
      },
      axisLabel: {
        inside: false,
        formatter: "{value}\n",
      },
    },
    dataZoom: [
      {
        type: "inside",
        disabled: true,
        throttle: 50,
        show: false,
        brushSelect: false,
        handleStyle: {
          borderColor: "rgba(0, 0, 0, 1)",
        },
        handleIcon: "roundRect",
        handleSize: "61%",
      },
    ],
    series: [],
    seriesCnt: "6",
    backgroundColor: "rgba(255,255,255,0)",
    titleColor: "#000000",
    subtitleColor: "#505050",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#ffffff",
    color: [
      "#3b82f6",
      "#454a73",
      "#94d2bd",
      "#ca6702",
      "#bb3e03",
      "#ae2012",
      "#9b2226",
      "#0a9396",
      "#454a73",
    ],
    borderColor: "#ccc",
    borderWidth: "0",
    visualMapColor: ["#8a7ca8", "#e098c7", "#cceffa"],
    legendTextColor: "#000000",
    kColor: "#e098c7",
    kColor0: "transparent",
    kBorderColor: "#e098c7",
    kBorderColor0: "#8fd3e8",
    kBorderWidth: "2",
    lineWidth: "1",
    symbolSize: "0",
    symbol: "emptyRoundRect",
    symbolBorderWidth: "0",
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaaaaa",
    mapLabelColor: "#000",
    mapLabelColorE: "#ffffff",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "#e098c7",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#000000",
        axisTickShow: false,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#000000",
        splitLineShow: false,
        splitLineColor: ["#eeeeee", "#333333"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: false,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#333",
        axisTickShow: true,
        axisTickColor: "#333",
        axisLabelShow: true,
        axisLabelColor: "#333",
        splitLineShow: true,
        splitLineColor: ["#ccc"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    ],
    axisSeperateSetting: false,
    toolboxColor: "#4100ff",
    toolboxEmphasisColor: "#000000",
    tooltipAxisColor: "#0013ff",
    tooltipAxisWidth: "3",
    timelineLineColor: "#8fd3e8",
    timelineLineWidth: 1,
    timelineItemColor: "#ff0000",
    timelineItemColorE: "#8fd3e8",
    timelineCheckColor: "#8fd3e8",
    timelineCheckBorderColor: "#8a7ca8",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#8fd3e8",
    timelineControlBorderColor: "#8fd3e8",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#8fd3e8",
    datazoomBackgroundColor: "rgba(0,0,0,0)",
    datazoomDataColor: "rgba(255,255,255,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333",
  };
  function areafunc(index) {
    if (names.length < 4) {
      option.series.lineStyle = { width: 0 };
      return {
        opacity: 0.5,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: option.color[index],
          },
          {
            offset: 1,
            color: "rgb(255, 255, 255,0)",
          },
        ]),
      };
    } else {
      return {
        opacity: 0.0,
      };
    }
  }
  function linefunc(params) {
    if (names.length == 1) {
      return 1;
    } else {
      return 2;
    }
  }
  function linecolorfunc(index) {
    if (names.length == 1) {
      return 1;
    } else {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: option.color[index],
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255,0)",
        },
      ]);
    }
  }
  rawdatas.map((e, index) => {
    option.series.push({
      name: names[index],
      showSymbol: false,
      type: "line",
      silent: true,
      smooth: true,
      data: e,
      lineStyle: {
        width: linefunc(),
        // color: linecolorfunc(index),
        opacity: 0.5,
      },
      emphasis: {
        disabled: true,
        focus: "none",
      },
      areaStyle: areafunc(index),
    });
  });
  return (
    <ReactEcharts
      option={option}
      className=" !h-full w-full z-10"
      opts={{ renderer: "canvas" }}
      lazyUpdate={false}
    />
  );
}
